/*!
=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, {useState, useContext, useEffect, useRef} from "react";
import PropTypes from "prop-types"
import { graphql, navigate } from "gatsby";
import { wrapUserConsumer, EntityUpdateHandler, locateEntityInData } from "../components/user-context";
import { mergeDeep } from "../utils/replacements";
import { getSectionStatus, hasRequirementsFulfilled, getScheduleIssues, convertToMsTime } from "../utils/questions";
import PolicyExample from "../components/form/PolicyExample.js";
import VideoEmbed from '../components/video-embed.js';
import ReactQuill from 'react-quill';
import HighlightReplace from '../components/highlight.js';
import 'react-quill/dist/quill.snow.css';
import { NotifyContext, navigateAndNotify } from "../components/notifications";
import LoadingOverlay from '../components/loading-overlay';


import Loadable from 'react-loadable';
import HTMLReactParser from "html-react-parser";
import '../argon-dashboard-pro-react-v1.0.0/src/assets/scss/argon-dashboard-pro-react.scss';

import ImagineSpinner from "../components/spinner"



// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardText,
  Form,
  Container,
  Row,
  Col,
} from "reactstrap";


function RadiosQuestion({title, options, onInput, invalidMessage, data}){
  let isInvalid = false;
  options = options.map((option) => {
    let inputClasses = "custom-control-input ";
    if(option.invalid){
      if (option.checked && !option.field_required_to_select) inputClasses += " is-invalid";
      isInvalid = true;
    }
    return (
      <div className="custom-control custom-radio mb-3" key={option.htmlId}>
        <input
          value={option.drupal_id}
          className={inputClasses}
          id={option.htmlId}
          name={title.replace(/(<([^>]+)>)/g,"").replace(/ /g, "-").toLowerCase()}
          type="radio"
          checked={option.checked}
          onChange={onInput}
        />
        <label
          className="custom-control-label"
          htmlFor={option.htmlId}
        >
          {option.text}
        </label>
      </div>
    )
  });
  let displayVal = isInvalid ? 'block' : 'hidden';
  let titleDisplay = HighlightReplace(title, data.nodeSection.queriedLanguage);
  return (
    <>
      <CardText tag="div">
        <h2 className="h2 mt-4 mb-0">{titleDisplay}</h2>
        { isInvalid ?
            (
              <div className="invalid-feedback" style={{
                display: 'block'
              }}>{invalidMessage}</div>
            ) : (
              <div className="invalid-feedback" style={{
                display: 'block'
              }}></div>
            )
        }
      </CardText>
      <CardBody>
        <Form>
          <Row>
            <Col md="12">{options}</Col>
          </Row>
        </Form>
      </CardBody>
    </>
  );
}

function CheckboxesQuestion({title, options, onChange, invalidMessage, data}){
  let isInvalid = false;
  options = options.map((option) => {
    let inputClasses = "custom-control-input ";
    if(option.invalid){
      if (option.checked && !option.field_required_to_select) inputClasses += " is-invalid";
      isInvalid = true;
    }
    else{
      inputClasses = "custom-control-input";
    }
    return (
      <div className="custom-control custom-checkbox mb-3" key={option.htmlId}>
        <input
          value={option.drupal_id}
          className={inputClasses}
          id={option.htmlId}
          name={title.replace(/(<([^>]+)>)/g,"").replace(/ /g, "-").toLowerCase()}
          type="checkbox"
          checked={option.checked}
          onChange={onChange}
        />
        <label
          className="custom-control-label"
          htmlFor={option.htmlId}
        >
          {option.text}
        </label>
      </div>
    );
  });
  let titleDisplay = HighlightReplace(title, data.nodeSection.queriedLanguage);
  return (
    <>
      <CardText tag="div">
        <h2 className="h2 mt-4 mb-0">{titleDisplay}</h2>
        { isInvalid ?
            (
              <div className="invalid-feedback" style={{
                display: 'block'
              }}>{invalidMessage}</div>
            ) : (
              <div className="invalid-feedback" style={{
                display: 'block'
              }}></div>
            )
        }
      </CardText>
      <CardBody>
        <Form>
          <Row>
            <Col md="12">
              { options }
            </Col>
          </Row>
        </Form>
      </CardBody>
    </>
  )

}

function TextQuestion({title, explanation, htmlId, value, onChange, isInvalid=false, data}){
  const inputClasses = isInvalid ? "is-invalid" : "";
  let titleDisplay = HighlightReplace(title, data.nodeSection.queriedLanguage);
  return (
    <>
      <CardText tag="div">
        { isInvalid ?
          (
            <div className="invalid-feedback" style={{
              display: 'block'
            }}>{explanation}</div>
          ) : (
            <div className="invalid-feedback" style={{
              display: 'block'
            }}></div>
          )
        }
      </CardText>
      <CardBody>
        <Form>
          <Row>
            <Col md="12">
              <label
                className="form-control-label"
                htmlFor={htmlId}
              >
                {titleDisplay}
              </label>
              <input
                className={inputClasses}
                value={value}
                onChange={onChange}
                id={htmlId}
                type="text"
                name={title.replace(/(<([^>]+)>)/g, "").replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").replace(/ /g, "-").toLowerCase()}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </>
  );

}

function EssayQuestion({title, explanation, example, htmlId, value, onChange, submit, data}) {

  const exampleAnswerContentIdMaps = {};
  let optionIdMap = {};
  // selected options.
  if (example) {
    let exampleQuestions = example.relationships.field_question_reference;
    const invalidState = {};
    for(let question of exampleQuestions){
      invalidState[question.drupal_id] = useState({});
    }

    exampleQuestions = exampleQuestions.map( (question) => {
      exampleAnswerContentIdMaps[question.drupal_id] = {};

      const displayOptions = [];

      optionIdMap = exampleAnswerContentIdMaps[question.drupal_id];

      const [invalidOptions, _] = invalidState[question.drupal_id],
        invalidMessage = HighlightReplace(question.field_explanation.value, data.nodeSection.queriedLanguage);

      for (let option of question.relationships.field_options) {
        let optionDisplayText = HTMLReactParser(option.field_opt_text.processed.replace(/<\/?p>/g,"")),
          optionHTMLId = option.field_opt_text.processed.replace(/(<([^>]+)>)/g,"").replace(/ /g, "-").toLowerCase() + '-' + option.drupal_id,
          invalid = invalidOptions.hasOwnProperty(option.drupal_id);

        let displayOption = {
          ...option,
          text: optionDisplayText,
          htmlId: optionHTMLId,
          invalid: invalid
        };

        displayOptions.push(displayOption);

        optionIdMap[optionHTMLId] = option.drupal_id;
      }


      let itemKey = question.field_question.value.toLowerCase().replace(/ /g, "-") 
        + '-' + data.nodeSection.queriedLanguage.toLowerCase();
      if (question.internal.type === 'node__radios_question'){
        return (
          <RadiosQuestion
            key={itemKey}
            title={question.field_question.value}
            options={displayOptions}
            //onInput={changeExampleRadioAnswer}
            invalidMessage={HighlightReplace(question.field_explanation.value, data.nodeSection.queriedLanguage)}
            data={data}
          />
        )
      }
      if (question.internal.type === 'node__checkboxes_question'){
        return (
          <CheckboxesQuestion
            key={itemKey}
            title={question.field_question.value}
            options={displayOptions}
            //onInput={changeExampleCheckboxAnswer}
            invalidMessage={HighlightReplace(question.field_explanation.value, data.nodeSection.queriedLanguage)}
            data={data}
          />
        )
      }
    })
    example.questions = exampleQuestions;
    example.exampleAnswerContentIdMaps = exampleAnswerContentIdMaps;
    example.optionIdMap = optionIdMap;
  }
  
  let titleDisplay = HighlightReplace(title, data.nodeSection.queriedLanguage);
  return (
        <>
          <Card>
            <CardText tag="div" className="px-4">
              <h2 className="h2 mt-4 mb-0">{titleDisplay}</h2>
              <small className="text-muted">
                {explanation}
              </small>
              <PolicyExample
                example={example}
                editor={htmlId}
                submit={submit}
              />
            </CardText>
            <CardBody>
              <Form className="mt-4 pb-4 border-bottom quill-form">
                <div
                  data-quill-placeholder="Quill WYSIWYG"
                  data-toggle="quill"
                > { (typeof window !== `undefined`)
                      ? <ReactQuill
                          value={value}
                          onChange={onChange}
                          id={htmlId}
                          theme="snow"
                          modules={{
                            toolbar: [
                              ["bold","italic"],
                              ["link", "blockquote", "code", "image"],
                              [
                                {
                                  list: "ordered"
                                },
                                {
                                  list: "bullet"
                                }
                              ]
                            ]
                          }}
                        />
                      : <>Loading...</>
                  }
                </div>
              </Form>
            </CardBody>
          </Card>
        </>
      )
}

/**
 *
 * @param title
 * @param fileName
 * @param setFileState
 * @param user
 * @returns {*}
 * @constructor
 */
function ImageQuestion({title, fileName, changeImageAnswer, inputId, data}){
  let titleDisplay = HighlightReplace(title, data.nodeSection.queriedLanguage);
  return (
    <>
      <CardText tag="div">
        <h2 className="h2 mt-4 mb-0">{titleDisplay}</h2>
      </CardText>
      <CardBody>
        <Form>
          <Row>
            <Col md="12">
              <div className="custom-file">
                <label
                  className="custom-file-label"
                  style={{cursor: "pointer"}}
                >
                  {fileName ? fileName : "Select an image"}

                  <input
                    style={{ display: "none" }}
                    className="custom-file-input"
                    type="file"
                    onChange={changeImageAnswer}
                    id={inputId}
                  />
                </label>
              </div>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </>
  );
}

function SectionTemplate({data, user}){

  if (typeof user === `undefined`){
    // This can be undefined in the build sometimes, so this is all I can think of TODO: show a shell?
    return (
      <>Loading...</>
    );
  }


  let sectionStatus = getSectionStatus(user.userData, data.nodeSection);

  let logHandler = user.getEntityUpdateHandler();
  let logBody = {
    data: {
      type: "node--section_status",
      attributes: {
        field_status: sectionStatus,
        title: "Section log " + data.nodeSection.title
      },
      relationships: {
        field_section: {
          data: {
            type: "node--section",
            id: data.nodeSection.drupal_id
          }
        }
      }
    }
  }



  let questions = data.nodeSection.relationships.field_questions;
  const videoUrl = data.nodeSection.field_video_overview;

  // we use state to determine if an answer has been declared invalid and an invalid message should be displayed in the
  // corresponding question
  const invalidState = {};
  // we want to set up state for each question before filtering the non-required ones, because you need the
  // same number of useState calls every render (and the number of questions can be variable after filtering)
  for(let question of questions){
    invalidState[question.drupal_id] = useState({});
    question.updateTimeoutState = useState(null);
  }

  const notificationAlert = useContext(NotifyContext);

  // VERIFY REQUIREMENTS:
  // in order to see and answer a question, a policy builder may have to have selected certain options beforehand.
  // these are known as the question's 'requirements'.

  // this loop filters out questions that should not be visible yet because their requirements are not fulfilled
  questions = questions.filter(hasRequirementsFulfilled.bind(null, user.userData));


  // we need to get content from the DOM to see how the user answered a question.
  // thus, for each question,
  // we must build a map that uses the HTML ids of input elements (like checkboxes or text fields) as keys.
  // then, we store each map in `allAnswerContentIdMaps` using the question's drupal_id as the key.
  const allAnswerContentIdMaps = {};

  /**
   * @param {object} optionIdMap A map stored in `allAnswerContentIdMaps` for a question with options
   *  (i.e radios or checkboxes)
   * @returns {array} objects representing all the options selected, formatted to go in the body of an answer request.
   */
  function getSelected(optionIdMap){
    if (typeof document === `undefined`){
      return [];
    }
    const currentSelected = [];
    for (let opEl of Object.keys(optionIdMap).map(oid => document.getElementById(oid))){
      if (opEl.checked){
        currentSelected.push({
          type: 'node--option',
          id: optionIdMap[opEl.id]
        });
      }
    }
    return currentSelected;
  }

  function highlightTextarea(inputId) { 
    if (typeof document === `undefined`){
      return;
    }

    const input = document.getElementById(inputId);
    const quillEditor = input.querySelector('.ql-editor');
    if(quillEditor){
      return quillEditor.setAttribute("style", "border: 1px solid red;");
    }
    else{
      return input.setAttribute("style", "border: 1px solid red;");;
    }
  }

  function unhighlightTextarea(inputId) { 
    if (typeof document === `undefined`){
      return;
    }

    const input = document.getElementById(inputId);
    const quillEditor = input.querySelector('.ql-editor');
    if(quillEditor){
      return quillEditor.setAttribute("style", "border: inheret;");
    }
    else{
      return input.setAttribute("style", "border: inheret;");;
    }
  }

  function getText(inputId){
    if (typeof document === `undefined`){
      return;
    }

    const input = document.getElementById(inputId);
    const quillEditor = input.querySelector('.ql-editor');
    if(quillEditor){
      return quillEditor.innerHTML;
    }
    else{
      return input.value;
    }
  }

  // this sets up the entity that contains all of a policy builder's answers using the EntityUpdateHandler API.
  // the EntityUpdateHandler will be used later when we are in the `changeAnswer` or `completeSection` event handler.
  // we'll need the entity to exist before we can create answers.
  const policyHandler = user.getEntityUpdateHandler();
  const policyBody = {
    data: {
      type: "node--policy",
      attributes: {
        title: "Policy",
      },
    }
  };
  policyHandler.bindEntity({
    body: policyBody
  });

  // Get types of events for schedules, needed when we create a schedule question or validate it
  const eventTypes = data.allTaxonomyTermEventType.nodes.reduce((acc, term) => {
    acc[term.name] = term.drupal_id;
    return acc;
  }, {});

  // We have to set a minHeight since changes to user data cause all the question components to
  // unmount and thus, we lose their height until it renders again. If we lose enough height, the scrollbar can get
  // pushed up and it wont return to its previous position when the component renders again. The effect is that the end
  // user sees their scroll bar jump up when they make a change, and then "randomly" again when the corresponding
  // request completes and the component gets an ID from the back end.
  // Setting a minHeight is undoubtedly a hack. It would be better to figure out how best to use the User Context API
  // in order to receive changes to entities that wont cause their parents to render
  const [minHeight, setMinHeight] = useState(0);

  // QUESTION COMPONENT CREATION:
  // create a component for each visible question, including an on change handler for updating the back end
  let displayQuestions = questions.map((question) => {
    // initialize the answer content id map for this question
    allAnswerContentIdMaps[question.drupal_id] = {};

    // we need to pass the options as a prop into the dumb question display component, thus we do all the processing
    // they will need to determine how to display each option, and pass in `displayOptions` as a prop
    const displayOptions = [];

    const optionIdMap = allAnswerContentIdMaps[question.drupal_id];

    // this sets up the answer entity for this question using the EntityUpdateHandler API.
    // the EntityUpdateHandler will be used when we are in the `changeAnswer` event handler, and is thus incomplete
    // the type and title are missing because we dont know if this is a select answer or a text answer yet
    const handler = user.getEntityUpdateHandler();
    const answerBody = {
      data: {
        attributes: {
          field_is_complete: false,
        },
        relationships: {
          field_question_reference: {
            data: [{
              type: question.internal.type.replace(/__/, "--"),
            }]
          },
        }
      }
    };

    // make sure our answer handler knows to add a reference to the new answer to the answer containing entity
    // handler.addRelationship('node--policy', 'field_answers');
    
    // Items in a list need to have unique IDs, this is used in the components that are returned
    // These should probably be meaningful for use later, so question + queried language makes sense
    let itemKey;
    if (question.hasOwnProperty('field_question')) {
      itemKey = question.field_question.value.toLowerCase().replace(/ /g, "-") 
        + '-' + data.nodeSection.queriedLanguage.toLowerCase();
    } else {
      // I think the only questions that don't have the field are schedule questions
      itemKey = 'schedule' + '-' + data.nodeSection.queriedLanguage.toLowerCase();
    }

    if (question.internal.type === 'node__radios_question' || question.internal.type === 'node__checkboxes_question') {
      // we dont have an essay or text question, so we must have a select answer with options
      answerBody.data.type = "node--select_answer";
      answerBody.data.attributes.title = "Select Answer";
      // our answer body has a type and title now, so we can bind it
      handler.bindEntity({
        body: answerBody,
        identifyingPropertyString: 'answers.relationships.field_question_reference.data.0.id',
        identifyingPropertyCondition: question.drupal_id
      });

      // get the answer entity, if it exists, to see which options are selected
      const currentAnswer = handler.getEntity();

      // get all the question's options which are invalid in the current state
      const [invalidOptions, _] = invalidState[question.drupal_id],
        // get a message that the question must display if any option is invalid
        invalidMessage = HighlightReplace(question.field_explanation.value, data.nodeSection.queriedLanguage);

      // process each option for display
      for (let option of question.relationships.field_options) {
        let optionDisplayText = HTMLReactParser(option.field_opt_text.processed.replace(/<\/?p>/g, "")),
          optionHTMLId = option.field_opt_text.processed.replace(/(<([^>]+)>)/g,"").replace(/ /g, "-").toLowerCase() + '-' + option.drupal_id,
          invalid = invalidOptions.hasOwnProperty(option.drupal_id);

        let displayOption = {
          ...option,
          text: optionDisplayText,
          htmlId: optionHTMLId,
          invalid: invalid
        };

        if (currentAnswer) {
          if (currentAnswer.hasOwnProperty('relationships') &&
            currentAnswer.relationships.hasOwnProperty('field_answer_option') &&
            currentAnswer.relationships.field_answer_option.hasOwnProperty('data')) {
            // we want to see if this option is in the answer, and mark it as checked for display if so
            displayOption.checked =
              currentAnswer.relationships.field_answer_option.data.map(ao => ao.id).includes(option.drupal_id);
          }
          else {
            // otherwise, display it as unchecked
            displayOption.checked = false;
          }
        }
        else {
          // if no answer exists, mark it as checked only if the option is selected by default
          displayOption.checked = option.field_selected_by_default;
        }
        displayOptions.push(displayOption);

        // add the element HTML id to our answerContentIdMap
        optionIdMap[optionHTMLId] = option.drupal_id;
      }

      async function changeRadioAnswer(e){
        const selectedId = e.target.value;
        const currentSelected = getSelected(optionIdMap).filter(option => option.id == selectedId);
        await handler.handle({
          body: mergeDeep(handler.entityBody, {
            data: {
              relationships: {
                field_answer_option: {
                  data: currentSelected
                }
              }
            }
          }),
          newIdentifyingValue: question.drupal_id,
          updateBackendTimeout: question.updateTimeoutState,
          updateBackendTimeoutLength: 0,
        });

        logBody.data.attributes.field_status = await getSectionStatus(user.userData, data.nodeSection);

        logHandler.handle({
          body: logBody,
          identifyingPropertyString: 'sectionStatus.relationships.field_section.data.id',
          identifyingPropertyCondition: data.nodeSection.drupal_id,
        });

      }

      async function changeCheckboxAnswer(e){
        const currentSelected = getSelected(optionIdMap);
        await handler.handle({
          body: mergeDeep(handler.entityBody, {
            data: {
              relationships: {
                field_answer_option: {
                  data: currentSelected
                }
              }
            }
          }),
          newIdentifyingValue: question.drupal_id,
          updateBackendTimeout: question.updateTimeoutState,
          updateBackendTimeoutLength: 0,
        });

        logBody.data.attributes.field_status = await getSectionStatus(user.userData, data.nodeSection);

        logHandler.handle({
          body: logBody,
          identifyingPropertyString: 'sectionStatus.relationships.field_section.data.id',
          identifyingPropertyCondition: data.nodeSection.drupal_id,
        });

      }

      if (question.internal.type === 'node__radios_question'){
        return (
          <RadiosQuestion
            key={itemKey}
            title={question.field_question.value}
            options={displayOptions}
            onInput={changeRadioAnswer}
            invalidMessage={invalidMessage}
            data={data}
          />
        )
      }
      if (question.internal.type === 'node__checkboxes_question'){
        return (
          <CheckboxesQuestion
            key={itemKey}
            title={question.field_question.value}
            options={displayOptions}
            onChange={changeCheckboxAnswer}
            invalidMessage={invalidMessage}
            data={data}
          />
        )
      }
    }
    else if(question.internal.type === 'node__text_question' || question.internal.type === 'node__essay_question'){
      // we have an essay or text question, so we need a text answer to it
      answerBody.data.type = "node--text_answer";
      answerBody.data.attributes.title = "Text Answer";
      // our answer body has a type and title now, so we can bind it
      handler.bindEntity({
        body: answerBody,
        identifyingPropertyString: 'answers.relationships.field_question_reference.data.0.id',
        identifyingPropertyCondition: question.drupal_id
      });

      const textAnswer = handler.getEntity();
      let text;
      try{
        text = textAnswer.attributes.field_answer.value;
      }
      catch{
        text = "";
      }
      const textAreaHTMLId = question.field_question.value.replace(/<\/?[^>]+(>|$)/g, "").replace(/[^a-zA-Z0-9]/g, "-").toLowerCase() + '-' + question.drupal_id;
      allAnswerContentIdMaps[question.drupal_id] = textAreaHTMLId;

      // this is the onChange event handler for essay questions
      async function changeTextAnswer(){
        const currentText = getText(textAreaHTMLId);
        const mergedBody = mergeDeep(handler.entityBody, {
          data: {
            attributes: {
              field_answer: {
                value: currentText
              }
            }
          }
        });
        // make sure answer container exists before we try to create an answer, as we will want to reference the answer
        // from the answer container
        // policyHandler.handle();
        // this creates or updates the answer to this question, modifying the request body to contain the text
        await handler.handle({
          body: mergedBody,
          newIdentifyingValue: question.drupal_id,
          updateBackendTimeout: question.updateTimeoutState,
          updateBackendTimeoutLength: 500,
        });


        logBody.data.attributes.field_status = await getSectionStatus(user.userData, data.nodeSection);

        logHandler.handle({
          body: logBody,
          identifyingPropertyString: 'sectionStatus.relationships.field_section.data.id',
          identifyingPropertyCondition: data.nodeSection.drupal_id,
        });


      }

      if(question.internal.type === 'node__text_question'){
        return (
          <TextQuestion
            key={itemKey}
            title={question.field_question.value}
            explanation={HighlightReplace(question.field_explanation.value, data.nodeSection.queriedLanguage)}
            htmlId={textAreaHTMLId}
            onChange={changeTextAnswer}
            value={text}
            data={data}
          />
        );
      }

      if(question.internal.type === 'node__essay_question'){
        return (
          <EssayQuestion
            key={itemKey}
            title={question.field_question.value}
            explanation={HighlightReplace(question.field_explanation.value, data.nodeSection.queriedLanguage)}
            example={question.relationships.field_policy_example}
            htmlId={textAreaHTMLId}
            value={text}
            onChange={changeTextAnswer}
            submit={completeExample}
            data={data}
          />
        );
      }
    }
    else if(question.internal.type === 'node__schedule_question'){
      const ScheduleQuestion = Loadable({
        loader: () => import('../components/section/schedule-question'),
        loading: ImagineSpinner,

      });

      return (
        <ScheduleQuestion 
          key="schedule"
          user={user}
          eventTypes={eventTypes}
          question={question}
          issues={invalidState[question.drupal_id]}
          minHeightState={[minHeight, setMinHeight]}
          scheduleTemplate={data.allNodeScheduleTemplate.edges[0].node}
        />
      );
    }
    else if(question.internal.type === 'node__image_question'){
      handler.bindEntity({
        body: answerBody,
        identifyingPropertyString: 'answers.relationships.field_question_reference.data.0.id',
        identifyingPropertyCondition: question.drupal_id
      });
      const imageAnswer = handler.getEntity();
      let fileName = null;
      if(imageAnswer &&
        imageAnswer.relationships &&
        imageAnswer.relationships.field_answer_image &&
        imageAnswer.relationships.field_answer_image.data &&
        imageAnswer.relationships.field_answer_image.data.attributes &&
        imageAnswer.relationships.field_answer_image.data.attributes.filename){
        fileName = imageAnswer.relationships.field_answer_image.data.attributes.filename;
      }
      answerBody.data.type = "node--image_answer";
      answerBody.data.attributes.title = "Image Answer";
      function changeImageAnswer(e){
        if(e.target.files[0]){
          answerBody.data.relationships.field_answer_image = {
            data: {
              type: 'file--file',
              attributes: {
                filename: e.target.files[0].name
              }
            }
          };
          const entityArgs = {
            body: answerBody,
            identifyingPropertyString: 'answers.relationships.field_question_reference.data.0.id',
            identifyingPropertyCondition: question.drupal_id,
            newIdentifyingValue: question.drupal_id,
            files: {
              field_answer_image: e.target.files[0]
            }
          };
          handler.handle(entityArgs);
        }
      }

      return (
        <ImageQuestion
          title={question.field_question.value}
          fileName={fileName}
          changeImageAnswer={changeImageAnswer}
          inputId={question.drupal_id}
          data={data}
        />
      );
    }
  });

  async function completeExample(ev, example) {
    if (typeof document === `undefined`) {
      return;
    }
    ev.preventDefault();
    let exampleAnswerContentIdMaps = example.exampleAnswerContentIdMaps;
    let questions = example.relationships.field_question_reference;
    let retObj = {isValid: true, invalidQuestions: {}, allOptions: {}, invalidOptions: {}};
    for(let question of questions) {
      let optionIdMap = exampleAnswerContentIdMaps[question.drupal_id],
        currentSelected = getSelected(optionIdMap),
        invalidOptions = {};
      for (let option of question.relationships.field_options) {
        retObj.allOptions[option.drupal_id] = option;
        if ((option.field_required_to_not_select && currentSelected.map(op => op.id).includes(option.drupal_id)) ||
          (option.field_required_to_select && !currentSelected.map(op => op.id).includes(option.drupal_id))
        ) {
          retObj.isValid = false;
          retObj.invalidOptions[option.drupal_id] = option;
          retObj.invalidQuestions[question.drupal_id] = question;
        }
      }
    }
    return retObj;
  }

  async function completeSection(ev){


    if (typeof document === `undefined`){
      return;
    }
    ev.preventDefault();
    const answerHandlers = [];
    let success = true;

    const notificationMessages = new Set(["You completed the section!"]); //TODO: move to translatable common phrase
    for(let question of questions){
      let isInvalid = false;
      let handler = user.getEntityUpdateHandler();
      let answerBody = {
        data: {
          attributes: {
            field_is_complete: true,
          },
          relationships: {
            field_question_reference: {
              data: [{
                type: question.internal.type.replace(/__/, "--"),
                id: question.drupal_id
              }]
            },
          }
        }
      };
      if(question.internal.type === "node__radios_question" || question.internal.type === "node__checkboxes_question") {
        let optionIdMap = allAnswerContentIdMaps[question.drupal_id],
          currentSelected = getSelected(optionIdMap),
          [_, invalidOptionsSetter] = invalidState[question.drupal_id],
          invalidOptions = {};
        if(currentSelected.length === 0){
          // none selected, assume this to be an error
          if(success){
            // we are going from success to failure, clear the success message
            notificationMessages.clear();
          }
          success = false;
          isInvalid = true;
          notificationMessages.add('One or more questions are missing an answer.');
        }
        for (let option of question.relationships.field_options) {
          if ((option.field_required_to_not_select && currentSelected.map(op => op.id).includes(option.drupal_id)) ||
            (option.field_required_to_select && !currentSelected.map(op => op.id).includes(option.drupal_id))
          ) {
            // this option is incorrectly selected or unselected
            invalidOptions[option.drupal_id] = option;
            if(success){
              // we are going from success to failure, clear the success message
              notificationMessages.clear();
            }
            success = false;
            isInvalid = true;
            notificationMessages.add('You answered one or more questions incorrectly.');

          }
        }
        invalidOptionsSetter(invalidOptions);
        answerBody.data.relationships.field_answer_option = {
          data: currentSelected
        };
        answerBody.data.type = "node--select_answer";
        answerBody.data.attributes.title = "Select Answer";
      }
      else if (question.internal.type === "node__essay_question" || question.internal.type === "node__text_question"){
        let textAreaId = allAnswerContentIdMaps[question.drupal_id];
        const text = getText(textAreaId);
        if(!text || !text.replace(/<.*?>/g, '')){
          if(success){
            // we are going from success to failure, clear the success message
            notificationMessages.clear();
          }
          success = false;
          isInvalid = true;
          notificationMessages.add('One or more questions are missing an answer.');
          // highlight textarea/quill editor
          highlightTextarea(textAreaId); 
        } else {
          unhighlightTextarea(textAreaId);
        }
        answerBody.data.attributes.field_answer = {
          value: text
        };
        answerBody.data.type = "node--text_answer";
        answerBody.data.attributes.title = "Text Answer";
      }
      else if (question.internal.type === "node__schedule_question"){
        const issues = Object.entries(getScheduleIssues(user, eventTypes, question));

        if(issues.length > 0){
          if(success){
            // we are going from success to failure, clear the success message
            notificationMessages.clear();
          }
          success = false;
          isInvalid = true;
          notificationMessages.add('There remain issues with your schedule, please resolve them before completing the section.');
        }
        answerBody.data.type = "node--schedule_answer";
        answerBody.data.attributes.title = "Schedule Answer";
      }
      else if (question.internal.type === "node__image_question"){
        if(user.userData.answers[question.drupal_id] &&
          user.userData.answers[question.drupal_id].relationships &&
          user.userData.answers[question.drupal_id].relationships.field_answer_image &&
          user.userData.answers[question.drupal_id].relationships.field_answer_image.data &&
          user.userData.answers[question.drupal_id].relationships.field_answer_image.data.attributes &&
          user.userData.answers[question.drupal_id].relationships.field_answer_image.data.attributes.filename){
          answerBody.data.type = "node--image_answer";
          answerBody.data.attributes.title = "Image Answer";
        }
        else{
          if(success){
            // we are going from success to failure, clear the success message
            notificationMessages.clear();
          }
          success = false;
          isInvalid = true;
          notificationMessages.add('No image chosen.');
        }

      }
      if(isInvalid){
        answerBody.data.attributes.field_is_complete = false;
      }
      const entityArgs = {
        body: answerBody,
        identifyingPropertyString: 'answers.relationships.field_question_reference.data.0.id',
        identifyingPropertyCondition: question.drupal_id
      };

      handler.bindEntity(entityArgs);
      // handler.addRelationship('node--policy', 'field_answers');

      const existingAnswer = handler.getEntity();

      answerHandlers.push(handler);
    }
    function getPath(langCode, path){
      const defaultLangcode = data.site.siteMetadata.langs.defaultLangcode;
      return (langCode === defaultLangcode) ? path : `${langCode}${path}`;
    }
    const notificationOptions = {
      place: "tc",
      message: {
        title: success ? "Success!" : "Please check your answers:",
        messages: notificationMessages
      },
      type: success ? "success" : "danger",
      icon: "ni ni-bell-55",
    };
    if(success){
      navigateAndNotify(getPath(data.nodeDashboard.queriedLanguage, data.nodeDashboard.path.alias), notificationOptions);
    }
    else{
      notificationAlert(notificationOptions);
    }
    // await policyHandler.handle();
    for(let answerHandler of answerHandlers){
      await answerHandler.handle();
    }

    sectionStatus = await getSectionStatus(user.userData, data.nodeSection);
    logBody.data.attributes.field_status = sectionStatus;

    logHandler.handle({
      body: logBody,
      identifyingPropertyString: 'sectionStatus.relationships.field_section.data.id',
      identifyingPropertyCondition: data.nodeSection.drupal_id,
    });


  }
  return (
    <>
      { !user.initialLoadingComplete || user.loading ? (<LoadingOverlay/>) : (<></>) }
      <Container className="mt--6" fluid>
        <Row className="justify-content-center">
          <Col className="card-wrapper" lg="8">
            <Card>
              <CardHeader className="bg-transparent">
                <small className="text-uppercase text-muted">
                  {data.nodeChapter.title}
                </small>
                <h1 className="py-3 mb-0">{data.nodeSection.title}</h1>
              </CardHeader>
              <CardBody>
                <div className="mt-4 pb-4 border-bottom card-text">
                  {HighlightReplace(data.nodeSection.field_explanation.value, data.nodeSection.queriedLanguage)}
                </div>
                {data.nodeSection.field_wac ?
                  <CardText className="mt-4 pb-4 border-bottom">
                    <Button
                      className="px-0 "
                      color="link"
                      target="_blank"
                      href={data.nodeSection.field_wac.uri}
                    >
                      {data.nodeSection.field_wac.title}
                    </Button>
                  </CardText> : <></>
                }
                {  videoUrl ? <VideoEmbed url={videoUrl} /> : <></> }
                <div className="questions" style={{ minHeight: `${minHeight}px`}}>
                  {
                    Object.keys(user.userData).length > 0 ? displayQuestions : (
                      <ImagineSpinner />
                    )
                  }
                </div>
                <CardText className="text-right mt-4 mb-4">
                  <Button color="primary" size="lg" type="button" onClick={completeSection}>
                    { data.nodeSection.relationships.field_complete_phrase.field_phrase_text.value }
                  </Button>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

SectionTemplate.propTypes = {
  user: PropTypes.shape({
    getEntityUpdateHandler: PropTypes.func.isRequired,
    userData: PropTypes.shape({
      // policy: PropTypes.object,
      answers: PropTypes.object
    }),
    dispatch: PropTypes.func.isRequired,

  })
};

export const query = graphql`
  query SectionQuery($sectionBasePath: String, $chapterBasePath: String, $queriedLanguage: String) {
    nodeDashboard(queriedLanguage: {eq: $queriedLanguage}) {
      queriedLanguage
      path {
        alias
      }
    }
    site {
      siteMetadata {
        langs {
          defaultLangcode
        }
      }
    }
    allTaxonomyTermEventType(filter: {queriedLanguage: {eq: $queriedLanguage}}) {
      nodes {
        drupal_id
        name
      }
    }
    allNodeScheduleTemplate(filter: {queriedLanguage: {eq: $queriedLanguage}}){
      edges {
        node {
          relationships {
            field_events {
              title
              relationships {
                field_event_type {
                  name
                  drupal_id
                }
              }
              field_end
              field_start
            }
          }
          field_close
          field_open
        }
      }
    }
    nodeChapter(path: {alias: {eq: $chapterBasePath}}, queriedLanguage: {eq: $queriedLanguage}) {
      title
    }
    nodeSection(path: {alias: {eq: $sectionBasePath}}, queriedLanguage: {eq: $queriedLanguage}) {
      drupal_id
      title
      field_video_overview
      queriedLanguage
      field_explanation {
        value
      }
      field_wac {
        title
        uri
      }
      relationships {
        field_questions {
          ... on node__radios_question {
            drupal_id
            title
            field_explanation {
              value
            }
            field_question {
              value
            } 
            internal {
              type
            }
            relationships {
              field_policy_output {
                field_replacement_text {
                  value
                }
                field_token
              }
              field_options {
                drupal_id
                field_opt_text {
                  processed
                }
                field_selected_by_default
                field_required_to_select
                field_required_to_not_select
              }
              field_requirements {
                drupal_id
              }
            }
          }
          ... on node__checkboxes_question {
            drupal_id
            internal {
              type
            }
            field_question {
              value
            }
            field_explanation {
              value
            }
            relationships {
              field_policy_output {
                field_replacement_text {
                  value
                }
                field_token
              }
              field_options {
                drupal_id
                field_opt_text {
                  processed
                }
                field_selected_by_default
                field_required_to_select
                field_required_to_not_select
              }
              field_requirements {
                drupal_id
              }
            }
          }
          ... on node__essay_question {
            drupal_id
            title
            internal {
              type
            }
            field_question {
              value
            }
            field_explanation {
              value
            }
            relationships {
              field_requirements {
                drupal_id
              }
              field_policy_output {
                field_replacement_text {
                  value
                }
                field_token
              }
              field_policy_example {
                field_example {
                  value
                }
                field_explanation {
                  value
                }
                relationships {
                  field_check_prompt {
                    field_phrase_text {
                      value
                    }
                  }
                  field_example_headline {
                    field_phrase_text {
                      value
                    }
                  }
                  field_explanation_headline {
                    field_phrase_text {
                      value
                    }
                  }
                  field_question_reference {
                    ... on node__radios_question {
                      drupal_id
                      internal {
                        type
                      }
                      field_explanation {
                        value
                      }
                      field_question {
                        value
                      }
                      relationships {
                        field_options {
                          drupal_id
                          field_opt_text {
                            processed
                          }
                          field_required_to_not_select
                          field_required_to_select
                          field_selected_by_default
                        }
                      }
                    }
                    ... on node__checkboxes_question {
                      drupal_id
                      internal {
                        type
                      }
                      field_explanation {
                        value
                      }
                      field_question {
                        value
                      }
                      relationships {
                        field_options {
                          drupal_id
                          field_opt_text {
                            processed
                          }
                          field_required_to_not_select
                          field_required_to_select
                          field_selected_by_default
                        }
                      }                     
                    }
                  } 
                }
              }
            }
          }
          ... on node__text_question {
            drupal_id
            title
            internal {
              type
            }
            field_question {
              value
            }
            field_explanation {
              value
            }
            relationships {
              field_requirements {
                drupal_id
              }
            }
          }
          ... on node__schedule_question {
            drupal_id
            title
            internal {
              type
            }
          }
          ... on node__image_question {
            drupal_id
            title
            field_question {
              value
            }
            internal {
              type
            }
            relationships {
              field_requirements {
                drupal_id
              }
            }
          }
        }
        field_complete_phrase {
          field_phrase_text {
            value
          }
        }
      }
    }
  }     
`;
export default wrapUserConsumer(SectionTemplate);
