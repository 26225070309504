import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import HTMLReactParser from 'html-react-parser'
import { ModalButton } from "../components/notifications"

const data = () => {
  const { allTaxonomyTermGlossary } = useStaticQuery(graphql` 
    query highlightTermQuery {
      allTaxonomyTermGlossary {
        edges {
          node {
            name
            description {
              processed
            }
            queriedLanguage
          }
        }
      }
    }
  `);
  return allTaxonomyTermGlossary.edges;
}

const Highlight = (props) => {
  const terms = data();
  const termToReplace = terms.filter(term => term.node.queriedLanguage === props.lang && term.node.name.toLowerCase() === props.term.toLowerCase());
  const returnTerm = termToReplace.length > 0 ? 
        <ModalButton
          type="highlight" 
          title={termToReplace[0].node.name} 
          body={HTMLReactParser(termToReplace[0].node.description.processed)} 
        /> : <>{props.term}</>
  return returnTerm
}

function tokenizeString(string, foundTerms) {
  const tokenizeStringRec = (string, foundTerms, tokens) => {
    if (!foundTerms.length) {
      tokens.push(string);
      return tokens;
    }
    let foundTerm = foundTerms.shift();
    if (!string) {
      tokens.push(foundTerm.term)
      return tokens;
    }
    let splitter = foundTerm.foundAt == 0 ? foundTerm.term + ' ' : ' ' + foundTerm.term + ' ';
    let regSplitter = new RegExp(`\\b${foundTerm.term}\\b`);
    let splitStr = string.split(regSplitter);
    if (splitStr.length > 1) {      
      if (foundTerm.foundAt == 0) {
        tokens.push(foundTerm.term);
      }
        tokens.push(splitStr.shift());
      if (foundTerm.foundAt != 0) {
       tokens.push(foundTerm.term);
      }
    }
    // string should only be split in two
    return tokenizeStringRec(splitStr.join(splitter), foundTerms, tokens);
  }
  string = string.trim() + ' ';
  return tokenizeStringRec(string, foundTerms, []);
}

const HighlightReplace = (html, queriedLanguage) => {
  const replace = domNode => {
    if (domNode.attribs && domNode.attribs.component === 'highlight') {
      return (
        <><Highlight term={domNode.children[0].data} lang={queriedLanguage} /> </>
      )
    } else if (domNode.type == 'text') {
      const terms = data();
      let termsFound = [];
      let tokenizedString = [];
      for (let term of terms) {
        if (term.node.queriedLanguage === queriedLanguage) {
          // to avoid regex using variables here, it might just be easier to check for upper and lower case individually
          // check uppercase
          let foundAt = domNode.data.indexOf(term.node.name);
          while (foundAt > -1) {
            // find all term occurrences
            termsFound.push({ term: term.node.name, foundAt: foundAt });
            foundAt = domNode.data.indexOf(term.node.name, foundAt+1);
          }
          // check lowercase
          foundAt = domNode.data.indexOf(term.node.name.toLowerCase());
          while (foundAt > -1) {
            // find all term occurrences
            termsFound.push({ term: term.node.name.toLowerCase(), foundAt: foundAt });
            foundAt = domNode.data.indexOf(term.node.name, foundAt+1);
          }
        }
      }
      termsFound.sort((a,b) => a.foundAt - b.foundAt);
      let tokens = tokenizeString(domNode.data, [...termsFound]);
      if (tokens.length) {
        return (
          <>
            {
              //map over the tokens and return either strings or Highlight components
              tokens.map(token => {
                for (let term of termsFound) {
                  if (token == term.term) {
                    return (<><Highlight term={token} lang={queriedLanguage} /> </>);
                  }
                }
                return token;
              })
            }
          </>
        );
      }
    }
  }

  return HTMLReactParser(html, { replace });
}

export default HighlightReplace

