import React from 'react';

import { Jumbotron } from 'reactstrap';

import '../../../web/modules/contrib/video_embed_field/css/video_embed_field.responsive-video.css';

function getEmbedUrl(url) {
  let id = [];
  if (url.includes('youtube')) {
    id = url.split('=');
    url = 'https://www.youtube.com/embed/' + id[id.length - 1]; 
  } else if (url.includes('vimeo')) {
    id = url.split('/');
    url = 'https://player.vimeo.com/video/' + id[id.length - 1];
  }
  return url;
}

export default function VideoEmbed({url}) {
  return (
    <div>
      <Jumbotron className='video-embed-field-responsive-video' style={{ padding: '0' }}>
        <iframe src={getEmbedUrl(url)} style={{padding: '30px'}}></iframe>
      </Jumbotron>
    </div>
  )
} 
