import React, { useState } from 'react';
import HTMLReactParser from 'html-react-parser';
import Quill from 'quill';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardTitle,
  Form,
  Row,
  Col
} from "reactstrap";

function ExampleModal({example, editor, submit}) {
  const [modalOpen, modalToggle] = useState(false);
  return (
    <>
      <Button 
        color="secondary" 
        size="md" 
        type="button" 
        className="mb-2"
        onClick={() => modalToggle(!modalOpen)}
      >
        View an example
      </Button>
      <Modal isOpen={modalOpen} toggle={() => modalToggle(!modalOpen)}>
        <div className="modal-body">
          <Card className="card-pricing bg-gradient-success border-0 text-left mb-4">
            <CardHeader className="bg-transparent">
              <h4 className="text-uppercase ls-1 text-white text-center py-3 mb-0">
                {example.relationships.field_example_headline ? 
                  HTMLReactParser(example.relationships.field_example_headline.field_phrase_text.value) :
                  'Example'
                }
              </h4>
            </CardHeader>
            <CardBody className="px-lg-7 mb-0">
              <span className="text-sm text-white">
                {HTMLReactParser(example.field_example.value)}
              </span>
            </CardBody>
            <Card className="bg-gradient-default ml-6 mr-6">
              <CardBody>
                <CardTitle className="text-white" tag="h3">
                  {example.relationships.field_explanation_headline ?
                    HTMLReactParser(example.relationships.field_explanation_headline.field_phrase_text.value) :
                    'Explanation'
                  }
                </CardTitle>
                <blockquote className="blockquote text-white mb-0">
                  {HTMLReactParser(example.field_explanation.value)}
                </blockquote>
              </CardBody>
            </Card>
            {example.relationships.field_check_prompt ? 
              <div className="ml-7 mr-7" style={{padding: '0px 20px 0px 0px', color: '#fff'}}>
                {example.relationships.field_check_prompt.field_phrase_text.value}
              </div> :
              ''
            }

            <CardBody className="px-lg-7">
              {example.questions}
            </CardBody>
          </Card>
        </div>
        <div className="modal-footer">
          <Button
            className="new-event--add"
            color="primary"
            type="button"
            onClick={ async (ev) => {
              if (typeof document !== `undefined`) {
                let submission = await submit(ev, example);
                if (submission.isValid) {
                  let editorElem = document.querySelector('#' + editor + ' div.ql-editor');
                  let text = example.field_example.value.replace(/\r?\n|\r/g, '').replace(/>\s+</g, '><');
                  editorElem.innerHTML = text + '<br />';
                  modalToggle(!modalOpen)
                } else {
                  for (let optionId in submission.allOptions) {
                    let option = submission.allOptions[optionId];
                    let htmlId = option.field_opt_text.processed
                      .replace(/<[^>]*>?/g, "")
                      .replace(/ /g, "-")
                      .toLowerCase() + '-' + option.drupal_id;
                    document.getElementById(htmlId).className = 'custom-control-input';   
                  }
                  for (let invalidOptionId in submission.invalidOptions) {
                    // show invalid message
                    let invalidOption = submission.invalidOptions[invalidOptionId];
                    let htmlId = invalidOption.field_opt_text.processed.replace(/<[^>]*>?/g, "").replace(/ /g, "-").toLowerCase() 
                      + '-' + invalidOption.drupal_id;
                    let docElem = document.getElementById(htmlId);
                    if (docElem.checked)  { 
                      docElem.className = 'custom-control-input is-invalid';
                    } 
                  }
                  for (let invalidQuestionId in submission.invalidQuestions) {
                    // show invalid message
                    let invalidQuestion = submission.invalidQuestions[invalidQuestionId];
                    for (let title of document.querySelectorAll('h2')) {
                      if (title.innerText.localeCompare(invalidQuestion.field_question.value.replace(/<[^>]*>?|\s$|\r|\n/g, "")) == 0) {
                        title.parentElement.querySelector('.invalid-feedback').innerHTML = invalidQuestion.field_explanation.value;
                      }
                    }
                  }
                }
              }
            }}
          >
            Use Example
          </Button>
          <Button
            className="ml-auto"
            color="link"
            type="button"
            onClick={() => modalToggle(!modalOpen)}
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  ) 
}

export default function PolicyExample({example, editor, submit}) {
  if (!example) return (<></>)
  return (
    <>
      <Card className="card-pricing bg-gradient-success border-0 text-left mb-4">
        <CardBody className="px-lg-7">
          <Row>
            <Col>
              <h4 className="text-uppercase ls-1 text-white text-left py-3 mb-0">
                Need inspiration?
              </h4>
            </Col>
            <Col className="text-right">
              <ExampleModal 
                example={example} 
                editor={editor} 
                submit={submit}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
